import PropTypes from 'prop-types';
import BackgroundVideo from '@activebrands/core-web/components/BackgroundVideo';
import Image from '@activebrands/core-web/components/Image';
import useSlider from '@activebrands/core-web/libs/slider/useSlider';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const Thumbnails = ({ $style = {}, items = [], slider = {} }) => {
    const [css] = useStyletron();
    const { index, isInfinite, numberOfSlides, slidesPerView } = useSlider(slider, [
        'index',
        'isInfinite',
        'numberOfSlides',
        'slidesPerView',
    ]);

    if (items.length < 2) {
        return null;
    }

    const hide = !isInfinite && numberOfSlides === slidesPerView;

    return (
        <div
            className={css({
                display: ['none', null, null, null, hide ? 'none' : 'flex'],
                position: 'absolute',
                bottom: '12px',
                left: '50%',
                transform: 'translateX(-50%)',
                gap: '4px',
                zIndex: 1,
                ...$style,
            })}
        >
            {items.map(({ url, type, typeValue }, i) => {
                const isTypeValueProduct = typeValue === 'Product';

                const typeValueProductStyles = isTypeValueProduct
                    ? {
                          padding: 'var(--padding-image-pdp)',
                          backgroundColor: 'var(--color-bg-image)',
                          objectFit: 'contain',
                      }
                    : {};

                const mediaStyles = {
                    border: `1px solid ${index === i ? 'var(--color-border)' : 'transparent'}`,
                    borderRadius: '4px',
                    width: '64px',
                    aspectRatio: 'var(--ratio-vertical-primary)',
                    overflow: 'hidden',
                };
                return (
                    <button key={url} type="button" onClick={() => slider.slideTo(i, true)}>
                        {type === 'image' && (
                            <Image
                                $style={{ height: '100%', ...mediaStyles, ...typeValueProductStyles }}
                                loading="eager"
                                sizes="64px"
                                src={{
                                    url,
                                    width: [100],
                                }}
                            />
                        )}
                        {type === 'video' && (
                            <div className={css({ position: 'relative', width: '100%', height: '100%' })}>
                                <BackgroundVideo
                                    $style={{
                                        ...mediaStyles,
                                        aspectRatio: 'var(--ratio-vertical-primary)',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    src={url}
                                />
                            </div>
                        )}
                    </button>
                );
            })}
        </div>
    );
};

Thumbnails.propTypes = {
    $style: PropTypes.object,
    items: PropTypes.array.isRequired,
    slider: PropTypes.object.isRequired,
};

export default Thumbnails;
